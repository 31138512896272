import React from "react"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { DefaultHeader } from "components/headers/default-header"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleDown } from "@fortawesome/free-solid-svg-icons"
import { Link as Scroll } from "react-scroll"

const Features = () => {
  return (
    <>
      <DefaultHeader />
      <Layout>
        <Seo
          pagetitle="機能紹介"
          pagepath="/features"
          pagedesc="機能紹介 | PARKLoT(パークロット)は「月額制」で「何回でも」実施できるTwitterキャンペーンツールを提供しています。"
        />
        <main className="function">
          <section className="function_introduction">
            <div className="function_introduction_container relative">
              <div>
                <h1 className="function_title mb-5">機能紹介</h1>
                <p className="function_lead">
                  手軽にキャンペーンが開始できるPARKLoTの機能紹介です。
                  <br />
                  インスタントウィン、自動抽選、結果通知、
                  <br />
                  レポート機能、住所収集など
                  <br />
                  <span className="tracking-tight">
                    キャンペーン開催に必要な機能が揃っています。
                  </span>
                </p>
              </div>
              <div className="function_introduction_img">
                <img
                  layout="constrained"
                  alt="機能紹介"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/features/top.png"
                />
              </div>
            </div>
          </section>
          <div className="mobile-spacing"></div>

          <section className="function_btn-background">
            <div className="function_btn_list flex m-auto">
              <Scroll
                className="function_container"
                to="campaign"
                smooth={true}
              >
                <div className="nav-heading">
                  <div className="nav-heading_direction">
                    <div>
                      <img
                        className="block m-auto"
                        layout="constrained"
                        alt="キャンペーン作成"
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/features/campaign.png"
                      />
                    </div>
                    <span className="text-base pt-1">キャンペーン作成</span>
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                </div>
              </Scroll>
              <Scroll className="function_container" to="win" smooth={true}>
                <div className="nav-heading">
                  <div className="nav-heading_direction">
                    <div>
                      <img
                        className="block m-auto"
                        layout="constrained"
                        alt="自動抽選"
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/features/win.png"
                      />
                    </div>
                    <span className="text-base pt-1">自動抽選</span>
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                </div>
              </Scroll>
              <Scroll className="function_container" to="mail" smooth={true}>
                <div className="nav-heading">
                  <div className="nav-heading_direction">
                    <div>
                      <img
                        className="block m-auto"
                        layout="constrained"
                        alt="結果通知"
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/features/mail.png"
                      />
                    </div>
                    <span className="text-base pt-1">結果通知</span>
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                </div>
              </Scroll>
              <Scroll className="function_container" to="report" smooth={true}>
                <div className="nav-heading">
                  <div className="nav-heading_direction">
                    <div>
                      <img
                        className="block m-auto"
                        layout="constrained"
                        alt="レポート機能"
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/features/report.png"
                      />
                    </div>
                    <span className="text-base pt-1">レポート機能</span>
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                </div>
              </Scroll>
              <Scroll
                className="function_container"
                to="location"
                smooth={true}
              >
                <div className="nav-heading">
                  <div className="nav-heading_direction">
                    <div>
                      <img
                        className="block m-auto"
                        layout="constrained"
                        alt="住所収集"
                        src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/features/location.png"
                      />
                    </div>
                    <span className="text-base pt-1">住所収集</span>
                  </div>
                  <FontAwesomeIcon icon={faAngleDown} size="lg" />
                </div>
              </Scroll>
            </div>
          </section>

          <section className="function_cv text-center m-auto md:pt-10 pt-5 bg-white">
            <p className="md:text-2xl my-1 text-xl">
              ＼ 今すぐ資料をダウンロード！ ／
            </p>
            <a
              className="btn-features_contact"
              href="https://hub.park-lot.com/document-download"
              target="_blank"
              rel="noreferrer"
            >
              3分でPARKLoTの機能が分かる
              <br />
              資料をダウンロード（無料）
            </a>
          </section>

          <section className="function_detail">
            <div id="campaign" className="function_green">
              <h2 className="function_subtitle">
                管理画面からキャンペーンを
                <br />
                ラクラク設定
              </h2>
              <p className="function_text">
                フォロー＆リツイートを条件としたインスタントウィンキャンペーンの開催が可能です。
                <br />
                最短1日で実施できます。
              </p>
              <div className="function_detail_img m-auto">
                <img
                  layout="constrained"
                  alt="管理画面からキャンペーンをラクラク設定"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/features/section01.png"
                />
              </div>
            </div>

            <div id="win" className="function_white">
              <h2 className="function_subtitle">面倒な抽選作業はゼロ</h2>
              <p className="function_text">
                応募者の中からランダムで当選者を抽選します。
                <br />
                フィルタリングで最低フォロワー数を絞り込むことが可能です。
              </p>
              <div className="function_detail_img m-auto">
                <img
                  layout="constrained"
                  alt="面倒な抽選作業はゼロ"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/features/section02.png"
                />
              </div>
            </div>

            <div id="mail" className="function_green">
              <h2 className="function_subtitle">抽選結果を自動でDM送信</h2>
              <p className="function_text">
                参加者への抽選結果は即時DMで自動送信されます。また後日抽選で当選者に一括送信もできます。
                <br />
                DM送信作業は一切不要です。
              </p>
              <div className="function_detail_img m-auto">
                <img
                  layout="constrained"
                  alt="抽選結果を自動でDM送信"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/features/section03.png"
                />
              </div>
            </div>

            <div id="report" className="function_white">
              <h2 className="function_subtitle">
                レポート機能で収集作業もナシ
              </h2>
              <p className="function_text">
                フォロワー数の推移、応募数などを自動で収集。管理画面からいつでも確認ができます。
              </p>
              <div className="function_detail_img m-auto">
                <img
                  layout="constrained"
                  alt="レポート機能で収集作業もナシ"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/features/section04.png"
                />
              </div>
            </div>

            <div id="location" className="function_green">
              <h2 className="function_subtitle">住所収集も簡単</h2>
              <p className="function_text">
                物理的なプレゼントの場合、当選者に発送住所を専用フォームで登録していただくことが可能です。
                <br />
                また登録された住所をcsvで一括出力ができます。
              </p>
              <div className="function_detail_img m-auto">
                <img
                  layout="constrained"
                  alt="住所収集も簡単"
                  src="https://parklot-lambda-resources.s3.ap-northeast-1.amazonaws.com/blog/features/section05.png"
                />
              </div>
            </div>
          </section>

          <section className="function_cv text-center m-auto md:pt-20 mt-14 pb-20">
            <p className="md:text-2xl my-1 text-xl">
              ＼ 今すぐ資料をダウンロード！ ／
            </p>
            <a
              className="btn-features_contact"
              href="https://hub.park-lot.com/document-download"
              target="_blank"
              rel="noreferrer"
            >
              3分でPARKLoTの機能が分かる
              <br />
              資料をダウンロード（無料）
            </a>
          </section>
        </main>
      </Layout>
    </>
  )
}

export default Features
